import React from "react";
import { IconType } from "react-icons";

import { TTailwindString, tw } from "utils/tw";

import { ActionID } from "./types";

type ButtonSize = "sm" | "md" | "lg";
type ButtonVariant = "primary" | "secondary" | "tertiary" | "danger";

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  id: ActionID;
  variant?: ButtonVariant;
  size?: ButtonSize;
  fullWidth?: boolean;
  LeadingIcon?: IconType;
  TrailingIcon?: IconType;
  className?: TTailwindString;
}

export default React.forwardRef(
  (
    {
      type = "button",
      variant = "primary",
      size = "lg",
      fullWidth,
      LeadingIcon,
      TrailingIcon,
      className,
      children,
      ...props
    }: React.PropsWithChildren<ButtonProps>,
    ref: React.ForwardedRef<HTMLButtonElement>
  ): JSX.Element => {
    const baseStyles = tw(
      className,
      "inline-flex",
      "items-center",
      "justify-center",
      "font-semibold",
      "border",
      "rounded",
      "shadow-sm",
      "focus:outline-none",
      "focus:ring-2",
      "focus:ring-offset-2",
      "focus:ring-gray-900"
    );

    const sizeStyles = (() => {
      switch (size) {
        case "sm":
          return tw("px-2.5", "py-1.5", "space-x-2", "text-xs");
        case "md":
          return tw("px-4", "py-2", "space-x-2", "text-sm");
        case "lg":
          return tw("px-6", "py-3", "space-x-2", "text-base");

        default:
          return tw();
      }
    })();

    const variantStyles = (() => {
      switch (variant) {
        case "primary":
          return props.disabled
            ? tw(
                "bg-gray-100",
                "border-transparent",
                "text-gray-500",
                "cursor-default"
              )
            : tw(
                "bg-primary",
                "border-transparent",
                "text-primary-contrast",
                "hover:bg-primary-hover"
              );

        case "secondary":
          return props.disabled
            ? tw("border-gray-100", "text-gray-300", "cursor-default")
            : tw(
                "bg-white",
                "border-primary",
                "text-primary-contrast",
                "hover:bg-primary"
              );

        case "tertiary":
          return props.disabled
            ? tw("border-gray-100", "text-gray-300", "cursor-default")
            : tw(
                "bg-white",
                "border-gray-300",
                "text-gray-700",
                "hover:bg-gray-100"
              );

        case "danger":
          return props.disabled
            ? tw(
                "bg-white",
                "border-gray-100",
                "text-gray-300",
                "cursor-default"
              )
            : tw(
                "bg-white",
                "border-error",
                "text-error",
                "hover:bg-error-light"
              );

        default:
          return tw();
      }
    })();

    const buttonStyles = tw(baseStyles, sizeStyles, variantStyles, {
      "w-full": fullWidth,
    });

    const iconBase = tw("shrink-0");
    const leadingStyles = iconBase;
    const trailingStyles = iconBase;

    const isClickable = props.onClick || type !== "button";
    const tabIndex = props.tabIndex ?? (!isClickable ? -1 : undefined);

    if (!isClickable)
      return (
        <div
          {...(props as React.ButtonHTMLAttributes<HTMLDivElement>)}
          className={buttonStyles}
          tabIndex={tabIndex}
        >
          {LeadingIcon && <LeadingIcon size={16} className={leadingStyles} />}

          <span className={tw("truncate")}>{children}</span>

          {TrailingIcon && (
            <TrailingIcon size={16} className={trailingStyles} />
          )}
        </div>
      );

    return (
      <button
        {...props}
        type={type}
        className={buttonStyles}
        tabIndex={tabIndex}
        data-testid="button"
        ref={ref}
      >
        {LeadingIcon && <LeadingIcon size={16} className={leadingStyles} />}

        <span className={tw("truncate")}>{children}</span>

        {TrailingIcon && <TrailingIcon size={16} className={trailingStyles} />}
      </button>
    );
  }
);
